<template>
  <div>
    <div style="margin-left: 10%;margin-top: 30%">
      <div style="margin-bottom: 10%">
        <el-tag type="primary" effect="dark" style="margin-right: 5%"> 午餐 </el-tag>
        <el-tag type="primary" effect="dark" > {{ lunch_peo_num }}人 </el-tag>
        <div style="margin-top: 5%" v-if="hour_now < 12 || hour_now >= 12">
          <div style="margin-bottom: 5%">
            <el-tag type="success" effect="dark" style="margin-right: 5%"> 午餐已上报部门: </el-tag>
            <div style="display: flex;margin-top: 5%;width: 100%;flex-wrap: wrap;">
              <div v-for="(item, index) in list_lunch_result_up" :key="index" style="margin-top: 1%;margin-right: 2%">
                <el-tag type="success" effect="dark" style="margin-right: 5%"> {{ item.departName }} : {{ item.peoNum }}人 </el-tag>
              </div>
            </div>
          </div>
          <div v-if="bdst === '1'">
            <el-tag type="danger" effect="dark" style="margin-right: 5%"> 午餐未上报部门: </el-tag>
            <div style="display: flex;margin-top: 5%;width: 100%;flex-wrap: wrap;">
              <div v-for="(item, index) in list_lunch_result" :key="index" style="margin-top: 1%;margin-right: 2%">
                <el-tag type="danger" effect="dark" style="margin-right: 5%"> {{ item }} </el-tag>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <el-tag type="warning" effect="dark" style="margin-right: 5%"> 晚餐 </el-tag>
        <el-tag type="warning" effect="dark" > {{ supper_peo_num }}人 </el-tag>
        <div style="margin-top: 5%" v-if="hour_now >= 12">
          <div style="margin-bottom: 5%">
            <el-tag type="success" effect="dark" style="margin-right: 5%"> 晚餐已上报部门: </el-tag>
            <div style="display: flex;margin-top: 5%;width: 100%;flex-wrap: wrap;">
              <div v-for="(item, index) in list_supper_result_up" :key="index" style="margin-top: 1%;margin-right: 2%">
                <el-tag type="success" effect="dark" style="margin-right: 5%"> {{ item.departName }} : {{ item.peoNum }}人 </el-tag>
              </div>
            </div>
          </div>
          <div v-if="bdst === '1'">
            <el-tag type="danger" effect="dark" style="margin-right: 5%"> 晚餐未上报部门: </el-tag>
            <div style="display: flex;margin-top: 5%;width: 100%;flex-wrap: wrap;">
              <div v-for="(item, index) in list_supper_result" :key="index" style="margin-top: 1%;margin-right: 2%">
                <el-tag type="danger" effect="dark" style="margin-right: 5%"> {{ item }} </el-tag>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setNowDate, setNowHou} from "@/utils/time"
import {ElMessage} from "element-plus";
export default {
  name: "get_peo_num",
  data(){
    return{
      lunch_peo_num: '0',
      supper_peo_num: '0',
      list_lunch_result: [],
      list_supper_result: [],
      hour_now: '',
      list_lunch_result_up: [],
      list_supper_result_up: [],
      bdst: ''
    }
  },
  created() {
    if (!this.$route.params.key){
      ElMessage.error('非法访问!')
    }else {
      this.hour_now = setNowHou(new Date());
      this.bdst = this.$route.params.bdst
      this.axios.post('/imdFoodPeoNum/getPeoNum', (response) => {
        for(let i = 0; i < response.obj.length; i++){
          this.lunch_peo_num = response.obj[0];
          this.supper_peo_num = response.obj[1];
          this.list_lunch_result = response.obj2[0];
          this.list_supper_result = response.obj2[1];
        }
        for(let i = 0; i < response.obj2[2].length; i++){
          if (response.obj2[2][i].foodName === '午餐'){
            this.list_lunch_result_up.push(response.obj2[2][i]);
          }
          if (response.obj2[2][i].foodName === '晚餐'){
            this.list_supper_result_up.push(response.obj2[2][i]);
          }
        }
      },{
        time: setNowDate(new Date()),
        bdst: this.bdst
      })
    }

  }
}
</script>

<style scoped>

</style>
